import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MasterDataModel } from "src/app/shared/services/master-data/entities/master-data.model";
import { MasterDataFacade } from "src/app/shared/services/master-data/master-data.facade";
import { MasterDataService } from "src/app/shared/services/master-data/master-data.service";
import { CreateEmployeeFacade } from "../domain/create-employee/create-employee.facade";
import { CreateEmployeeService } from "../domain/create-employee/create-employee.service";

@Component({
  selector: 'app-create-employee-wizard',
  templateUrl: './create-employee-wizard.component.html',
  providers: [
    {
      provide: MasterDataFacade,
      useClass: MasterDataService,
    },
    {
      provide: CreateEmployeeFacade,
      useClass: CreateEmployeeService,
    }
  ]
})

export class CreateEmployeeWizardComponent implements OnInit {
  salutation: MasterDataModel[];
  department: MasterDataModel[];
  role: MasterDataModel[];


  constructor(
    public modalService: NgbModal,
    public modal: NgbActiveModal,
    public masterDataService: MasterDataFacade,
    public dataService: CreateEmployeeFacade) {
  }

  ngOnInit(): void {
    this.masterDataService.getMasterDataByKey("Salutation").then(
      data => this.salutation = data
    )

    this.masterDataService.getMasterDataByKey("Department").then(
      data => this.department = data
    )

    this.masterDataService.getMasterDataByKey("UserRole").then(
      data => this.role = data
    )
  }

  create() {
    this.dataService
      .addEmployee()
      .then(() => {
        console.log('Success')
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getSalutationByCode(code: string) {
    this.masterDataService.getMasterDataByCode(code, "Salutation")
      .then((data) => {
        this.dataService.setSalutation(code, data.name);
      })
  }

  getDepartmentByCode(code: string) {
    this.masterDataService.getMasterDataByCode(code, "Department")
      .then((data) => {
        this.dataService.setDepartment(code, data.name);
      })
  }
}