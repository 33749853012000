import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, of } from "rxjs";
import { catchError, map, first } from "rxjs/operators";
import { EmployeeHttpClient } from "src/app/api/user-profile.api.service";
import { EmployeeDtoModel } from "../entities/employee-dto.model";
import { CreateEmployeeFacade } from "./create-employee.facade";

@Injectable()
export class CreateEmployeeService implements CreateEmployeeFacade {

  constructor(private employeeService: EmployeeHttpClient) { }

  private staffNoSubject = new BehaviorSubject<string>('');
  staffNo$ = this.staffNoSubject.asObservable();

  private usernameSubject = new BehaviorSubject<string>('');
  username$ = this.usernameSubject.asObservable();

  private nameSubject = new BehaviorSubject<string>('');
  name$ = this.nameSubject.asObservable();

  private myKadSubject = new BehaviorSubject<string>('');
  myKad$ = this.myKadSubject.asObservable();

  private emailSubject = new BehaviorSubject<string>('');
  email$ = this.emailSubject.asObservable();

  private phoneNoSubject = new BehaviorSubject<string>('');
  phoneNo$ = this.phoneNoSubject.asObservable();

  private addressSubject = new BehaviorSubject<string>('');
  address$ = this.addressSubject.asObservable();

  private salutationSubject = new BehaviorSubject<string>(null);
  salutation$ = this.salutationSubject.asObservable();

  private salutationCodeSubject = new BehaviorSubject<string>(null);
  salutationCode$ = this.salutationCodeSubject.asObservable();

  private departmentSubject = new BehaviorSubject<string>(null);
  department$ = this.departmentSubject.asObservable();

  private departmentCodeSubject = new BehaviorSubject<string>(null);
  departmentCode$ = this.departmentCodeSubject.asObservable();

  private rolesSubject = new BehaviorSubject<string[]>([]);
  roles$ = this.rolesSubject.asObservable();

  public employee$ = combineLatest([
    this.staffNo$,
    this.department$,
    this.departmentCode$,
    this.roles$,
    this.username$,
    this.name$,
    this.phoneNo$,
    this.email$,
    this.myKad$,
    this.address$,
    this.salutation$,
    this.salutationCode$,
  ]).pipe(
    map(
      ([
        staffNo,
        department,
        departmentCode,
        roles,
        userName,
        name,
        phoneNumber,
        email,
        myKad,
        address,
        salutation,
        salutationCode,
      ]) => {

        return {
          staffNo,
          department,
          departmentCode,
          roles,
          userName,
          name,
          phoneNumber,
          email,
          myKad,
          address,
          salutation,
          salutationCode,
        } as EmployeeDtoModel;
      })
  );

  setStaffNo(staffNo: string) {
    this.staffNoSubject.next(staffNo);
  }

  setUsername(username: string) {
    this.usernameSubject.next(username);
  }

  setName(name: string) {
    this.nameSubject.next(name);
  }

  setMyKad(mykad: string) {
    this.myKadSubject.next(mykad);
  }

  setEmail(email: string) {
    this.emailSubject.next(email);
  }

  setPhoneNo(phoneNo: string) {
    this.phoneNoSubject.next(phoneNo);
  }

  setAddress(address: string) {
    this.addressSubject.next(address);
  }

  setSalutation(code: string, name: string) {
    this.salutationCodeSubject.next(code);
    this.salutationSubject.next(name);
  }

  setDepartment(code: string, name: string) {
    this.departmentCodeSubject.next(code);
    this.departmentSubject.next(name);
  }

  setRoles(roles: string[]) {
    this.rolesSubject.next(roles);
  }

  addEmployee(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.employee$.pipe(first())
        .subscribe((employee) => {
          this.employeeService.addEmployeeProject(employee)
            .pipe(catchError(() => of(null)))
            .subscribe({
              next: (response) => {
                resolve(response.response);
              },
              error: (error) => {
                reject(error);
              },
            });
        });
    });
  }
}
