import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'design-system-table-footer',
    templateUrl: './table-footer.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class TableFooterComponent {
    @Input() firstRowOnPage!: number;

    @Input() lastRowOnPage!: number;

    @Input() collectionSize!: number;

    @Input() currentPage!: number;

    @Input() pageCount!: number;

    @Input() pageSize!: number;

    @Output() pageChange = new EventEmitter<void>();
  }