import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AddMasterDataDto, MasterDataHttpClient, MasterDataViewModel } from "src/app/api/master-data.api.service";
import { MasterDataPagedModel } from "./entities/master-data-paged.model";
import { MasterDataModel } from "./entities/master-data.model";
import { MasterDataFacade } from "./master-data.facade";

@Injectable()
export class MasterDataService implements MasterDataFacade {

    private masterDataSubject = new BehaviorSubject<MasterDataModel[]>([]);
    public masterData$ = this.masterDataSubject.asObservable();

    private masterDataByIdSubject = new BehaviorSubject<MasterDataModel>(null);
    public masterDataById$ = this.masterDataByIdSubject.asObservable();

    private masterDataByCodeSubject = new BehaviorSubject<MasterDataModel>(null);
    public masterDataByCode$ = this.masterDataByCodeSubject.asObservable();

    private masterDataPaginationSubject = new BehaviorSubject<MasterDataPagedModel>(null);
    public masterDataPagination$ = this.masterDataPaginationSubject.asObservable();

    // private salutationSubject = new BehaviorSubject<MasterDataModel[]>([]);
    // public salutation$ = this.salutationSubject.asObservable();

    // private departmentSubject = new BehaviorSubject<MasterDataModel[]>([]);
    // public department$ = this.departmentSubject.asObservable();

    // private roleSubject = new BehaviorSubject<MasterDataModel[]>([]);
    // public role$ = this.roleSubject.asObservable();

    constructor(private masterDataService: MasterDataHttpClient) { }


    addMasterData(data: AddMasterDataDto): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.masterDataService.addMasterData(data)
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (response) => {
                        resolve(response.response);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    updateMasterData(data: MasterDataViewModel): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.masterDataService.updateMasterData(data)
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (response) => {
                        resolve(response.response);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    getMasterData(id: string): Promise<MasterDataViewModel> {
        return new Promise((resolve, reject) => {
            this.masterDataService.getMasterData(id)
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (data) => {
                        const masterData = data.response.data;
                        this.masterDataSubject.next(masterData);
                        resolve(masterData);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    getMasterDataByCode(code: string, key: string): Promise<MasterDataViewModel> {
        return new Promise((resolve, reject) => {
            this.masterDataService.getMasterDataByCode(code, key)
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (data) => {
                        const masterData = data.response.data;
                        this.masterDataSubject.next(masterData);
                        resolve(masterData);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    getMasterDataByKey(key: string): Promise<MasterDataViewModel[]> {
        return new Promise((resolve, reject) => {
            this.masterDataService.getMasterDataByKey(key)
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (data) => {
                        const masterData = data.response.data;
                        resolve(masterData);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    loadPagination(pageNumber: string, itemsPerPage: string, orderBy: string, isDescending: string, name: string): Promise<MasterDataPagedModel> {
        return new Promise((resolve, reject) => {
            this.masterDataService.getMasterDataPagination(
                pageNumber,
                itemsPerPage,
                orderBy,
                isDescending,
                name
            )
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (data) => {
                        const pagedResult = data.response.data;
                        const masterData = data.response.data.results;
                        this.masterDataPaginationSubject.next(masterData);
                        resolve(pagedResult);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }
}