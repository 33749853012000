import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-detail-employee',
    templateUrl: './detail-employee.component.html',
  })
  
  export class DetailEmployeeComponent {
    @Input() title: string;
    
    constructor(
        public modal: NgbActiveModal){}

    save(){
        console.log('Save update')
    }

    closeModal() {
        this.modal.dismiss();
    }
  }