<div class="dropdown" ngbDropdown>
    <a class="font-size-16 text-muted dropdown-toggle" role="button"
        ngbDropdownToggle data-toggle="dropdown"
        aria-haspopup="true">
        <i class="mdi mdi-dots-vertical"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
        <a ngbDropdownItem (click)="edit.emit()">Edit</a>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem (click)="remove.emit()">Remove</a>
    </div>
</div>