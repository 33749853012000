import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "src/app/shared/ui/dialog/confirm-dialog/confirm-dialog.component";
import { CreateEmployeeWizardComponent } from "../create-employee-wizard/create-employee-wizard.component";
import { DetailEmployeeComponent } from "../detail-employee/detail-employee.component";
import { EmployeePagedModel } from "../domain/entities/employee-paged.model";
import { EmployeeOverviewFacade } from "../domain/overview/employee-overview.facade";
import { EmployeeOverviewService } from "../domain/overview/employee-overview.service";

@Component({
    selector: 'app-employee-overview',
    templateUrl: './employee-overview.component.html',
    providers: [
      {
        provide: EmployeeOverviewFacade,
        useClass: EmployeeOverviewService,
      },
      NgbActiveModal,
    ]
})

export class EmployeeOverviewComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  pageNumber = "1";
  itemsPerPage = "10";
  orderBy = "Name";
  isDescending = "true";
  name = "";

  constructor(public dataService: EmployeeOverviewFacade, public modalService: NgbModal, public modal: NgbActiveModal) {}

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'User Profile' }, { label: 'Employee', active: true }];

    this.dataService.loadPagination(this.pageNumber, this.itemsPerPage, this.orderBy, this.isDescending, this.name);
  }

  createEmployee(modal: any) {
    modal.close();
    this.modalService.open(CreateEmployeeWizardComponent, { size: 'lg', animation: false, backdrop: true, centered: true })
  }

  loadPage(page: any) {
    this.dataService.loadPagination(page, this.itemsPerPage, this.orderBy, this.isDescending, this.name);
  }

  edit(modal: any, employee: string) {
    modal.close();
    let modalRef = this.modalService.open(DetailEmployeeComponent, { size: 'lg', animation: false, backdrop: true, centered: true });
    modalRef.componentInstance.employee = employee;
    modalRef.componentInstance.title = "Edit employee";
  }

  remove(modal: any, employee: string) {
    modal.close();
    let modalRef = this.modalService.open(ConfirmDialogComponent, { size: 'lg', animation: false, backdrop: true, centered: true });
    modalRef.componentInstance.title = "Delete employee";
    modalRef.componentInstance.message = "Do you really want to remove this employee from list ?";
    modalRef.componentInstance.ok = "Ok";
    modalRef.componentInstance.cancel = "Cancel";
  }
}