import { Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { EmployeeHttpClient } from "src/app/api/user-profile.api.service";
import { EmployeePagedModel } from "../entities/employee-paged.model";
import { EmployeeModel } from "../entities/employee.model";
import { EmployeeOverviewFacade } from "./employee-overview.facade";

@Injectable()
export class EmployeeOverviewService implements EmployeeOverviewFacade {

    private employeePaginationSubject = new BehaviorSubject<EmployeePagedModel>(null);
    public employeePagination$ = this.employeePaginationSubject.asObservable();

    private employeesSubject = new BehaviorSubject<EmployeeModel[]>([]);
    public employees$ = this.employeesSubject.asObservable();

    constructor(private employeeService: EmployeeHttpClient) { }

    public loadPagination(pageNumber: string, itemsPerPage: string, orderBy: string, isDescending: string, name: string): Promise<EmployeePagedModel> {
        return new Promise((resolve, reject) => {
            this.employeeService.getEmployeePagination(
                pageNumber,
                itemsPerPage,
                orderBy,
                isDescending,
                name
            )
                .pipe(catchError(() => of(null)))
                .subscribe({
                    next: (data) => {
                        const pagedResult = data.response.data;
                        this.employeePaginationSubject.next(pagedResult);
                        this.employeesSubject.next(pagedResult.results);
                        resolve(pagedResult);
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }
}