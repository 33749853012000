import { Observable } from "rxjs";
import { EmployeeDtoModel } from "../entities/employee-dto.model";

export abstract class CreateEmployeeFacade {

    abstract employee$: Observable<EmployeeDtoModel>;

    abstract staffNo$: Observable<string>;
    
    abstract username$: Observable<string>;
    
    abstract name$: Observable<string>;
    
    abstract myKad$: Observable<string>;
    
    abstract email$: Observable<string>;
    
    abstract phoneNo$: Observable<string>;
    
    abstract address$: Observable<string>;
    
    abstract salutation$: Observable<string>;
    
    abstract salutationCode$: Observable<string>;
    
    abstract department$: Observable<string>;
    
    abstract departmentCode$: Observable<string>;
    
    abstract roles$: Observable<string[]>;
    
    abstract setStaffNo(staffNo: string): void;
    
    abstract setUsername(username: string): void;
    
    abstract setName(name: string): void;
    
    abstract setMyKad(mykad: string): void;
    
    abstract setEmail(email: string): void;

    abstract setPhoneNo(phoneNo: string): void;
    
    abstract setAddress(address: string): void;

    abstract setSalutation(code:string, name: string): void;

    abstract setDepartment(code: string, name: string): void;

    abstract setRoles(roles: string[]): void;

    abstract addEmployee(): Promise<boolean>;
}