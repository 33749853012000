<div class="modal-header">
    <h5 class="modal-title mt-0">Edit Employee</h5>
    <button type="button" class="btn-close" aria-label="Close"
        (click)="closeModal()">
    </button>
</div>
<div class="modal-body">
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="save()">Save</button>
</div>