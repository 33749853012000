import { Observable } from "rxjs";
import { EmployeePagedModel } from "../entities/employee-paged.model";
import { EmployeeModel } from "../entities/employee.model";

export abstract class EmployeeOverviewFacade {
    
    abstract employeePagination$: Observable<EmployeePagedModel>;

    abstract employees$: Observable<EmployeeModel[]>;

    abstract loadPagination(pageNumber: string, itemsPerPage: string, orderBy: string, isDescending: string, name: string): Promise<EmployeePagedModel>
}