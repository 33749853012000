import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { LoaderComponent } from './loader/loader.component';
import { TableFooterComponent } from './table-footer/table-footer.component';
import { ActionsDialogComponent } from './dialog/actions-dialog/actions-dialog.component';
@NgModule({
  declarations: [
    PagetitleComponent,  
    LoaderComponent,
    TableFooterComponent,
    ActionsDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbPaginationModule,
  ],
  exports: [
    PagetitleComponent, 
    LoaderComponent,
    TableFooterComponent,
    ActionsDialogComponent
  ]
})
export class UIModule { }
