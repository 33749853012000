import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-project-overview',
    templateUrl: './overview.component.html'
})

export class OperationProjectOverviewComponent implements OnInit {
  breadCrumbItems: Array<{}>;

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'Projects' }, { label: 'Projects Overview', active: true }];
  }
}