<div class="row justify-content-md-between align-items-md-center mt-2">
    <div class="col-sm-12 col-md-5">
      <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
        Showing {{ firstRowOnPage }} to {{ lastRowOnPage }} of {{ pageCount }} entries
      </div>
    </div>
    <!-- Pagination -->
    <div class="col-sm-12 col-md-5">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination 
          (pageChange)="pageChange.emit($event)" 
          [collectionSize]="collectionSize" 
          [(page)]="currentPage" 
          [pageSize]="pageSize">
        </ngb-pagination>
      </div>
    </div>
    <!-- End Pagination -->
</div>