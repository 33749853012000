import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-confirm-dialog',
    template:`
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
  
      <h2 class="close-btn" (click)="modal.close()"><i class="icon-close"></i></h2>
    </div>
    <div class="modal-body">
    <p [innerHtml]="message"></p>
    </div>
    <div class="modal-footer btn-block">
        <button type="button" class="btn btn-outline-primary" (click)="modal.dismiss()">{{cancel}}</button>
        <button type="button" ngbAutofocus class="btn btn-primary" (click)="modal.close(true)">{{ok}}</button>
    </div>
    `,
    styles: []
  })
  export class ConfirmDialogComponent {
  
    @Input() title: string = "";
    @Input() message: string = "";
    @Input() ok: string = "";
    @Input() cancel: string = "";
  
    constructor(public modal: NgbActiveModal) { }
  
  }