import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARD.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 2,
        label: 'MENUITEMS.OPERATION_PROJECTS.TEXT',
        icon: 'bx-briefcase-alt-2',
        subItems: [
            {
                id: 3,
                label: 'MENUITEMS.OPERATION_PROJECTS.LIST.OVERVIEW',
                link: '/projects',
                parentId: 2
            },
            {
                id: 4,
                label: 'MENUITEMS.OPERATION_PROJECTS.LIST.TASKS',
                link: '/projects/tasks#',
                parentId: 2
            }
        ]
    },
    {
        id: 5,
        label: 'MENUITEMS.USER_PROFILE.TEXT',
        icon: 'bxs-group',
        subItems: [
            {
                id: 6,
                label: 'MENUITEMS.USER_PROFILE.LIST.BUSINESS_CLIENT',
                link: '/user-profile/business-client',
                parentId: 5
            },
            {
                id: 7,
                label: 'MENUITEMS.USER_PROFILE.LIST.EMPLOYEE',
                link: '/user-profile/employee',
                parentId: 5
            }
        ]
    }
];

