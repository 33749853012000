
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CreateProjectWizardComponent } from './create-project-wizard/create-project-wizard.component';
import { OperationProjectComponent } from './operation-project.component';
import { OperationProjectOverviewComponent } from './overview/overview.component';

const routes: Routes = [
    {
        path: 'projects',
        component: OperationProjectComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
          {
            path: 'overview',
            component: OperationProjectOverviewComponent,
          },
          {
            path: 'create-project',
            component: CreateProjectWizardComponent,
          }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class OperationProjectRoutingModule {}
