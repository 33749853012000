import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'design-system-actions-dialog',
  templateUrl: './actions-dialog.component.html'
})
export class ActionsDialogComponent implements OnInit {

  @Output() edit = new EventEmitter<void>();

  @Output() remove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }
}