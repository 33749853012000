import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbDropdownModule, NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { LetModule } from "@rx-angular/template";
import { ArchwizardModule } from "angular-archwizard";
import { UIModule } from "../shared/ui/ui.module";
import { CreateEmployeeWizardComponent } from "./create-employee-wizard/create-employee-wizard.component";
import { EmployeeRoutingModule } from "./employee-routing.module";
import { EmployeeComponent } from "./employee.component";
import { EmployeeOverviewComponent } from "./overview/employee-overview.component";

@NgModule({
    declarations: [
        EmployeeComponent,
        EmployeeOverviewComponent,
        CreateEmployeeWizardComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UIModule,
        EmployeeRoutingModule,
        NgbPaginationModule,
        NgbDropdownModule,
        ArchwizardModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        LetModule
    ],
})
export class EmployeeModule { }