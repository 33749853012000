import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UIModule } from "../shared/ui/ui.module";
import { OperationProjectRoutingModule } from "./operation-project-routing.module";

import { OperationProjectComponent } from "./operation-project.component";
import { OperationProjectOverviewComponent } from "./overview/overview.component";

@NgModule({
    declarations: [
        OperationProjectComponent,
        OperationProjectOverviewComponent,
    ],
    imports: [
        CommonModule,
        OperationProjectRoutingModule,
        RouterModule,
        UIModule
    ],
})
export class OperationProjectModule { }