import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbCarouselModule, NgbDropdownModule, NgbNavModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from "../shared/shared.module";
import { UIModule } from "../shared/ui/ui.module";
import { WidgetModule } from "../shared/widget/widget.module";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardComponent } from "./dashboard.component";
import { DashboardOverviewComponent } from "./overview/overview.component";

@NgModule({
    declarations: [
        DashboardComponent,
        DashboardOverviewComponent,
    ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        UIModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbNavModule,
        NgbCarouselModule,
        WidgetModule,
        NgApexchartsModule,
        SharedModule
    ],
})
export class DashboardModule { }