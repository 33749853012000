import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { UIModule } from "../shared/ui/ui.module";
import { BusinessClientRoutingModule } from "./business-client-routing.module";
import { BusinessClientComponent } from "./business-client.component";
import { BusinessClientOverviewComponent } from "./overview/business-client-overview.component";

@NgModule({
    declarations: [
        BusinessClientComponent,
        BusinessClientOverviewComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UIModule,
        BusinessClientRoutingModule
    ],
})
export class BusinessClientModule { }