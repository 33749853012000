<div class="container-fluid">
  <app-page-title title="Employee" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">

          <div class="row mb-2">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input type="text" class="form-control rounded" placeholder="Search..."/>
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="text-sm-end">
                <button type="button" class="btn btn-primary mb-2 mr-2" (click)="createEmployee(modal)">
                  Add Employee
                </button>
              </div>
            </div>
            <!-- end col-->
          </div>
          
          <ng-container *ngIf="dataService.employeePagination$ | async as page">
            <ng-container *ngIf="page === null || page.results.length === 0; else data">
              <div class="row mt-4 mb-4">
                <span class="text-md-center text-primary">No records for you to see.</span>
              </div>
            </ng-container>

            <ng-template #data>
              <div class="table-responsive">
                <table id="basic-datatable"
                  class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
                  <thead>
                    <tr>
                      <th>Staff No.</th>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Username</th>
                      <th>Phone No.</th>
                      <th>Department</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let employee of page.results">
                      <tr>
                        <td>{{ employee.staffNo }}</td>
                        <td>{{ employee.name }}</td>
                        <td>{{ employee.roles }}</td>
                        <td>{{ employee.userName }}</td>
                        <td>{{ employee.phoneNumber }}</td>
                        <td>{{ employee.department }}</td>
                        <td>
                          <design-system-actions-dialog
                            (edit)="edit(modal, employee.staffNo)"
                            (remove)="remove(modal, employee.staffNo)"
                          ></design-system-actions-dialog>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <design-system-table-footer
                [firstRowOnPage]="page.firstRowOnPage"
                [lastRowOnPage]="page.lastRowOnPage"
                [(currentPage)]="page.currentPage"
                [collectionSize]="page.rowCount" 
                [pageCount]="page.pageCount" 
                [pageSize]="page.pageSize"
                (pageChange)="loadPage($event)"
              ></design-system-table-footer>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
