<div class="modal-header">
    <h5 class="modal-title mt-0">Add New Employee</h5>
    <button type="button" class="btn-close" aria-label="Close"
        (click)="modal.dismiss('Close click')">
    </button>
</div>
<div class="modal-body">
    <form #f="ngForm">
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Staff No.</label>
            <div class="col-md-4">
                <input 
                    type="text" 
                    name="staffNo"
                    class="form-control" 
                    placeholder="Staff No."
                    [ngModel]="dataService.staffNo$ | async"
                    (ngModelChange)="dataService.setStaffNo($event)">
            </div>
            <label class="col-md-2 col-form-label">Username</label>
            <div class="col-md-4">
                <input 
                    type="text"
                    name="username" 
                    class="form-control" 
                    placeholder="Username"
                    [ngModel]="dataService.username$ | async"
                    (ngModelChange)="dataService.setUsername($event)">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Name</label>
            <div class="col-md-10">
                <input 
                    type="text"
                    name="name"
                    class="form-control" 
                    placeholder="Name"
                    [ngModel]="dataService.name$ | async"
                    (ngModelChange)="dataService.setName($event)">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">MyKad</label>
            <div class="col-md-10">
                <input 
                    type="text" 
                    name="mykad"
                    class="form-control" 
                    placeholder="MyKad"
                    [ngModel]="dataService.myKad$ | async"
                    (ngModelChange)="dataService.setMyKad($event)">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Email</label>
            <div class="col-md-10">
                <input 
                    type="email" 
                    name="email"
                    class="form-control"
                    placeholder="Email"
                    [ngModel]="dataService.email$ | async"
                    (ngModelChange)="dataService.setEmail($event)">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Phone Number</label>
            <div class="col-md-10">
                <input 
                    type="text" 
                    name="phoneNo"
                    class="form-control"
                    placeholder="Phone Number"
                    [ngModel]="dataService.phoneNo$ | async"
                    (ngModelChange)="dataService.setPhoneNo($event)">
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Address</label>
            <div class="col-md-10">
                <textarea 
                    class="form-control" 
                    rows="3"
                    placeholder="Address"
                    name="address"
                    [ngModel]="dataService.address$ | async"
                    (ngModelChange)="dataService.setAddress($event)"></textarea>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Salutation</label>
            <div class="col-md-10">
                <ng-select 
                    name="salutation"
                    [items]="salutation" 
                    bindLabel="name" 
                    bindValue="code"
                    [ngModel]="dataService.salutation$ | async"
                    (ngModelChange)="getSalutationByCode($event)"></ng-select>
            </div>
        </div>

        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Department</label>
            <div class="col-md-10">
                <ng-select 
                    name="department"
                    [items]="department" 
                    bindLabel="name" 
                    bindValue="code"
                    [ngModel]="dataService.department$ | async"
                    (ngModelChange)="getDepartmentByCode($event)"></ng-select>
            </div>
        </div>
        <div class="row mb-3">
            <label class="col-md-2 col-form-label">Roles</label>
            <div class="col-md-10">
                <ng-select
                    name="roles" 
                    [items]="role"
                    [multiple]="true"
                    bindLabel="name" 
                    bindValue="code"
                    [ngModel]="dataService.roles$ | async"
                    (ngModelChange)="dataService.setRoles($event)"></ng-select>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary waves-effect" data-bs-dismiss="modal" (click)="modal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary waves-effect waves-light" (click)="create()">Save</button>
</div>