import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class AuthenticationService2 {
    username: string;
    loginDisplay = false;
    readonly _destroying$ = new Subject<void>();

    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

    constructor(public msalService: MsalService, private broadcastService: MsalBroadcastService,) { }


    getAuthenticatedUser() {
        this.broadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.checkAndSetActiveAccount();
                this.setLoginDisplay();
                this.isAuthenticatedSubject.next(true);
            })
    }

    setLoginDisplay() {
        this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
        //this.username = this.authService.instance.getActiveAccount()?.username!;
        this.username = this.msalService.instance.getActiveAccount()?.name!;
    }

    checkAndSetActiveAccount() {
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    /**
   * Logout the user
   */
    logout() {
        this.msalService.logout();
    }
}