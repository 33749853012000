import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CreateEmployeeWizardComponent } from './create-employee-wizard/create-employee-wizard.component';
import { EmployeeComponent } from './employee.component';
import { EmployeeOverviewComponent } from './overview/employee-overview.component';

const routes: Routes = [
    {
        path: 'user-profile/employee',
        component: EmployeeComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full',
          },
          {
            path: 'overview',
            component: EmployeeOverviewComponent,
          },
          {
            path: 'create',
            component: CreateEmployeeWizardComponent,
          }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EmployeeRoutingModule {}
