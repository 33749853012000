import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-business-client-overview',
    templateUrl: './business-client-overview.component.html'
})

export class BusinessClientOverviewComponent implements OnInit {
  breadCrumbItems: Array<{}>;

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'User Profile' }, { label: 'Business Client', active: true }];
  }
}