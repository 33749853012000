import { Observable } from "rxjs";
import { MasterDataDto } from "./entities/master-data-dto.model";
import { MasterDataPagedModel } from "./entities/master-data-paged.model";
import { MasterDataModel } from "./entities/master-data.model";

export abstract class MasterDataFacade {
    
    abstract masterData$: Observable<MasterDataModel[]>;

    abstract masterDataById$: Observable<MasterDataModel>;

    abstract masterDataByCode$: Observable<MasterDataModel>;

    abstract masterDataPagination$: Observable<MasterDataPagedModel>;

    abstract addMasterData(data: MasterDataDto): Promise<string>;
    
    abstract updateMasterData(data: MasterDataModel): Promise<string>;
    
    abstract getMasterData(id: string): Promise<MasterDataModel>;
   
    abstract getMasterDataByCode(code: string, key: string): Promise<MasterDataModel>;
   
    abstract getMasterDataByKey(key: string): Promise<MasterDataModel[]>;

    abstract loadPagination(pageNumber: string, itemsPerPage: string, orderBy: string, isDescending: string, name: string): Promise<MasterDataPagedModel>
}